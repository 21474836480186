import Title from '@components/atoms/Title';
import Buttons from '@components/Buttons';
import { Column, Columns } from '@components/website/Columns';
import Image from '@components/website/Image';
import ResponsiveVideo from '@components/website/ResponsiveVideo';
import { ModuleHeroType } from '@types';
import cx from 'classnames';
import { useInView } from 'framer-motion';
import { useRef } from 'react';

import SectionWrap from '../ModuleSection';
import styles from './ModuleHero.module.scss';

const ModuleHero: React.FC<ModuleHeroType> = ({
  title,
  subtitle,
  buttons,
  variant,
  responsiveVideo,
  backgroundImage
}) => {
  const containerRef = useRef<HTMLElement>(null);

  const hasButtons = buttons?.items;
  const hasMultipleButtons = hasButtons?.length > 1;

  const inView = useInView(containerRef);

  return (
    <SectionWrap ref={containerRef} className={styles.hero}>
      <div className={cx(styles.fixedWrap, { [styles.hidden]: !inView })}>
        {variant === 'backgroundImage' ? (
          <Image
            className={styles.image}
            src={backgroundImage}
            alt={backgroundImage?.alt}
            sizes="100vw"
            objectFit="cover"
          />
        ) : (
          <ResponsiveVideo
            canPlay={inView}
            src={responsiveVideo}
            variant={variant}
            coverSizes="100vw"
          />
        )}

        {variant !== 'videoPlayer' && (
          <Columns className={styles.columns}>
            <Column
              className={cx(
                styles.column,
                subtitle && styles.titleColumn,
                hasMultipleButtons && styles.multiple
              )}
              start={6}
              span={7}
            >
              <Title className={styles.title} as="h1" variant={'h1'}>
                {title}
              </Title>
            </Column>
            <Column
              className={cx(
                styles.column,
                subtitle && styles.subtitleColumn,
                hasMultipleButtons && styles.multiple
              )}
              start={7}
              span={5}
            >
              {subtitle && (
                <Title className={styles.subtitle} color="white">
                  {subtitle}
                </Title>
              )}
              {hasButtons && (
                <Buttons
                  {...buttons}
                  className={cx(
                    styles.buttons,
                    hasMultipleButtons && styles.multipleButtons
                  )}
                />
              )}
            </Column>
          </Columns>
        )}
      </div>
    </SectionWrap>
  );
};

export default ModuleHero;
